import React from "react"
import { graphql } from "gatsby"
import JotformEmbed from 'react-jotform-embed';
import Seo from "../components/seo"
import Layout from "../components/layout"
import { ContactForm } from "../components/contactForm";
const ContactPage = () => (
    <Layout>
        <div className="bg-white drop-shadow-xl border-t-7 md:px-8 px-4 mt-8 pb-6 max-w-6xl mx-auto ">
            <h2 className="text-3xl text-gray-600 leading-7 uppercase font-light title pb-5 mt-7 text-center mb-8">Contact Us</h2>
            <p className="text-sm text-gray-700 font-light max-w-full mb-3 ">Vinyl Fence Toronto serves the Greater Toronto Area (GTA), offering commercial and residential Toronto vinyl fence sales and installations. We deal in a vast array of different fencing solutions, including vinyl fences, aluminum fencing, simulated stone fences, chain link fences and beyond.</p>
            <p className="text-sm text-gray-700 font-light max-w-full mb-3 ">We offer free, no-obligation price quotes, so we invite you to fill out the contact form below and one of our friendly fencing specialists will be in touch to provide you with a free estimate for your fence installation project.!</p>
            <p className="text-sm text-gray-700 font-light max-w-full mb-0">Please fill the below form and one of our Toronto vinyl fence specialists will give you free estimate.</p>
            <div className="grid md:grid-cols-2 gap-8">
                <div className="flex items-center">
                    <ContactForm />
                    {/* <iframe src="https://form.jotformeu.com/212723489827264" title="Free Quote Request Form" className="w-full min-h-[500px] border-0" loading="lazy" /> */}
                    {/* <JotformEmbed src="https://form.jotformeu.com/212723489827264" /> */}
                </div>
                <div className="addr-row md:mt-8">
                    <div className="flex ">
                        <div className="mr-3 mt-0 ">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"><path d="M21 13v10h-6v-6h-6v6h-6v-10h-3l12-12 12 12h-3zm-1-5.907v-5.093h-3v2.093l3 3z" /></svg></div>
                        <p className="text-sm font-normal text-gray-900 mb-4  ">Address: 7250 Keele St #326 Concord, ON L4K 1Z8</p>
                    </div>
                    <div className="flex ">
                        <div className="mr-3 mt-0 ">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"><path d="M19 2c0-1.104-.896-2-2-2h-10c-1.104 0-2 .896-2 2v20c0 1.104.896 2 2 2h10c1.104 0 2-.896 2-2v-20zm-8.5 0h3c.276 0 .5.224.5.5s-.224.5-.5.5h-3c-.276 0-.5-.224-.5-.5s.224-.5.5-.5zm1.5 20c-.553 0-1-.448-1-1s.447-1 1-1c.552 0 .999.448.999 1s-.447 1-.999 1zm5-3h-10v-14.024h10v14.024z" /></svg></div>
                        <p className="text-sm font-normal text-gray-900 mb-4 ">Phone: 1-855-864-9274</p>
                    </div>
                    <div className="flex ">
                        <div className="mr-3 mt-0 ">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"><path d="M12 12.713l-11.985-9.713h23.97l-11.985 9.713zm0 2.574l-12-9.725v15.438h24v-15.438l-12 9.725z" /></svg></div>
                        <p className="text-sm font-normal text-gray-900 mb-4">Email:<a className="text-sm font-normal text-gray-700 max-w-full no-underline hover:text-cyan-400" href="#">leads@vinylfencetoronto.ca</a></p>
                    </div>
                    <p className="text-sm font-normal text-gray-900 mr-2 pl-8 mb-4 ">Winter hours 9:00-3:30 and Summer hours (starting April 2017) 8:00-5:00.</p>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2880.306689709094!2d-79.50037692399026!3d43.78724877109604!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b2e414fbac581%3A0xc7d710c10914ab5d!2s7250%20Keele%20St%20%23326%2C%20Concord%2C%20ON%20L4K%201Z8%2C%20Canada!5e0!3m2!1sen!2sin!4v1715059717013!5m2!1sen!2sin" width="100%" height="300" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    {/* <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d5763.165182108758!2d-79.46795!3d43.760764!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b33ff5f81a81b%3A0x62ca1bc0897a04bc!2s1450%20Lodestar%20Rd%2C%20North%20York%2C%20ON%20M3J%203C1%2C%20Canada!5e0!3m2!1sen!2sin!4v1676531398898!5m2!1sen!2sin" width="100%" height="300" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
                </div>
            </div>
        </div>
    </Layout>
)

export const Head = (props) => <Seo
    title={props?.data?.markdownRemark?.frontmatter?.meta?.seoTitle}
    description={props?.data?.markdownRemark?.frontmatter?.meta?.seoDescription}
    path={props.location.pathname}
/>


export const pageQuery = graphql`
  query ContactQuery{
    markdownRemark(frontmatter: {slug: {eq: "contact-us"}}) {
      frontmatter {
        content
        meta {
          seoTitle
          seoDescription
        }
      }
    }
  }
`;
export default ContactPage